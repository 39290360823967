import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { DocumentType } from '~app/hooks/useDocuments';

export const GET_UPLOADABLE_DOCUMENTS = gql`
  query handoverUploadableDocument($market: SiteLocale!) {
    handoverUploadableDocument(locale: $market) {
      documents {
        systemName
      }
    }
  }
`;

export interface GetUploadableDocumentsParams {
  market: string;
}

export interface GetUploadableDocumentsResponse {
  handoverUploadableDocument: {
    documents: Array<{
      systemName: DocumentType;
    }>;
  };
}

export const getUploadableDocumentsMock = (
  input: GetUploadableDocumentsParams,
  result: GetUploadableDocumentsResponse = {
    handoverUploadableDocument: {
      documents: [{ systemName: 'BankDocument' }, { systemName: 'DriversLicense' }]
    }
  }
): {
  request: { query: DocumentNode; variables: GetUploadableDocumentsParams };
  result: { data: GetUploadableDocumentsResponse | undefined };
} => ({
  request: {
    query: GET_UPLOADABLE_DOCUMENTS,
    variables: input
  },
  result: {
    data: result
  }
});
