import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { DocumentType } from '~app/hooks/useDocuments';

export const SEARCH_DOCUMENTS = gql`
  query documents($input: SearchDocumentsRequest!) {
    documents: searchDocuments(input: $input) {
      documentType
      documentId
      dateCreated
      size
      contentType
      subType
    }
  }
`;

export type DocumentData = {
  documentType: DocumentType;
  documentId: string;
  dateCreated: string;
  size: number;
  contentType: string;
  subType?: string;
};

export enum DocumentSearchType {
  ORDER_NUMBER = 'ORDER_NUMBER',
  REFERENCE_ID = 'REFERENCE_ID'
}

export type SearchDocumentsRequest = {
  input: {
    searchVal: string;
    searchType: DocumentSearchType;
    documentType?: DocumentType;
    subType?: string;
  };
};

export type SearchDocumentsResponse = {
  documents: Array<DocumentData>;
};

export const searchDocumentsApolloMock = (
  input: SearchDocumentsRequest,
  result?: Array<DocumentData>
): {
  request: { query: DocumentNode; variables: SearchDocumentsRequest };
  result: { data: SearchDocumentsResponse };
} => ({
  request: {
    query: SEARCH_DOCUMENTS,
    variables: input
  },
  result: {
    data: result
      ? {
          documents: result
        }
      : { documents: [] }
  }
});
