import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_DOCUMENT_LINK = gql`
  query documentLink($input: GetDocumentLinkRequest!) {
    documentLink: getDocumentLink(input: $input)
  }
`;

export type ContentDisposition = 'Attachment' | 'Inline' | 'Formdata' | 'Signal';

export interface GetDocumentLinkParams {
  input: { id: string; contentDisposition: ContentDisposition; downloadFileName?: string };
}

export interface GetDocumentLinkResponse {
  documentLink: string;
}

export const getDocumentLinkMock = (
  input: GetDocumentLinkParams,
  result: GetDocumentLinkResponse = { documentLink: '' }
): {
  request: { query: DocumentNode; variables: GetDocumentLinkParams };
  result: { data: GetDocumentLinkResponse };
} => ({
  request: {
    query: GET_DOCUMENT_LINK,
    variables: input
  },
  result: {
    data: result
  }
});
