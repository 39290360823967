import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { FinancialProducts } from '~app/enums';

export const GET_HANDOVER_EXTRAS = gql`
  query extras($input: GetHandoverExtrasRequest!) {
    extras: getHandoverExtras(input: $input) {
      extrasJson
    }
  }
`;

export interface GetHandoverExtrasRequest {
  input: { acceptLanguage: string; orderNumber: string };
}

export interface GetHandoverExtrasResponse {
  extras: { extrasJson: string };
}

export const getHandoverExtrasApolloMock = (
  input: GetHandoverExtrasRequest,
  result?: string
): {
  request: { query: DocumentNode; variables: GetHandoverExtrasRequest };
  result: { data: GetHandoverExtrasResponse | undefined };
} => ({
  request: {
    query: GET_HANDOVER_EXTRAS,
    variables: input
  },
  result: {
    data: result
      ? {
          extras: { extrasJson: result }
        }
      : undefined
  }
});

export interface Extra {
  id: number;
  article_number: string;
  title: string;
  description: string;
  sortorder: number;
  product_type: string | null;
  requires: Array<{
    type: string;
    code: string;
  }>;
  incompatible: Array<{
    type: string;
    code: string;
  }>;
}

export interface SalesItem {
  id: number;
  article_number: string;
  title: string;
  description: string;
  is_recommended: number;
  featured_image_url: string;
  market_price: number;
  max_quantity: number;
  min_quantity: number;
  extras: Array<number>;
  financing_options: Array<{
    customer_type: 'B2C' | 'B2B';
    financial_product: FinancialProducts;
  }>;
  market_vat: number;
  default_market_vat: number;
  currency: string;
  product_type: string;
  sales_period_starts_at: null | string;
  sales_period_ends_at: null | string;
}

export interface ExtrasContract {
  data?: {
    sales_categories: Array<{
      id: number;
      title: string;
      description: string;
      sortorder: number;
      is_mutex: boolean;
      featured_image_url: string;
      sales_items: Array<SalesItem>;
    }>;
    extras: Array<Extra>;
  };
}

export const extrasMock: ExtrasContract = {
  data: {
    sales_categories: [
      {
        id: 10,
        title: '',
        description: '',
        sortorder: 10,
        is_mutex: false,
        featured_image_url: '',
        sales_items: [
          {
            id: 10,
            article_number: '',
            title: '',
            description: '',
            is_recommended: 10,
            featured_image_url: '',
            market_price: 10,
            max_quantity: 10,
            min_quantity: 10,
            extras: [],
            financing_options: [
              {
                customer_type: 'B2C',
                financial_product: FinancialProducts.CASH
              }
            ],
            market_vat: 10,
            default_market_vat: 10,
            currency: '',
            product_type: '',
            sales_period_starts_at: '',
            sales_period_ends_at: ''
          }
        ]
      }
    ],
    extras: [
      {
        id: 10,
        article_number: '',
        title: '',
        description: '',
        sortorder: 10,
        product_type: '',
        requires: [
          {
            type: '',
            code: ''
          }
        ],
        incompatible: [
          {
            type: '',
            code: ''
          }
        ]
      }
    ]
  }
};
