import {
  Dialog,
  Spacer,
  Spacings,
  Row,
  PrimaryButton,
  Orientations,
  Text,
  Heading
} from '@polestar/component-warehouse-react';
import { InternalSecondaryButton } from '@polestar/component-warehouse-react/internal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Handover, HandoverTableData } from '~root/src/app/apollo/queries/getHandoverExtended';
import { getOriginLocationFromHandover } from '~root/src/app/helpers/handoverHelper';
import useHandoverCard from '~root/src/app/hooks/ActivityCards/useHandoverCard';
import useAuthState from '~root/src/app/hooks/useAuthState';
import useDatoState from '~root/src/app/hooks/useDatoState';
import useOriginPageState from '~root/src/app/hooks/useOriginPageState';
import { Path } from '~root/src/routes/definition';
import { generateUrl } from '~root/src/routes/helpers';

type Props = {
  setIsLocationInfoDialogOpen: (value: boolean) => void;
  chosenOrderLocation: string;
  chosenOrderNumber: string;
  handover: HandoverTableData;
  resetMarketView?: boolean;
};

const ConfirmChangeLocationDialog = ({
  setIsLocationInfoDialogOpen,
  chosenOrderLocation,
  chosenOrderNumber,
  handover,
  resetMarketView
}: Props) => {
  const { text } = useDatoState();
  const navigate = useNavigate();
  const { setUserMarketId } = useAuthState();
  const { pushOriginLocation } = useOriginPageState();

  return (
    <Dialog
      detached
      withCloseButton
      data-testid="confirm-change-location-dialog"
      open={true}
      onClose={() => setIsLocationInfoDialogOpen(false)}
    >
      <Heading level={2}>{text('ChangeLocationTitle')}</Heading>
      <Spacer spacing={Spacings.xLarge} />
      <Text>
        {text('ChangeLocationDescription').replace('{handover_location}', chosenOrderLocation)}
      </Text>
      <Spacer spacing={Spacings.xLarge} />
      <Row>
        <PrimaryButton
          onClick={() => {
            setIsLocationInfoDialogOpen(false);
            pushOriginLocation(getOriginLocationFromHandover(handover));
            resetMarketView && setUserMarketId(undefined);
            navigate(generateUrl(Path.HANDOVER, { id: chosenOrderNumber }));
          }}
          data-testid="accept-location-change-button"
        >
          {text('YesContinue')}
        </PrimaryButton>
        <Spacer spacing={Spacings.large} orientation={Orientations.horizontal} />
        <InternalSecondaryButton
          onClick={() => setIsLocationInfoDialogOpen(false)}
          data-testid="cancel-location-change-button"
        >
          {text('Cancel')}
        </InternalSecondaryButton>
      </Row>
    </Dialog>
  );
};

export default ConfirmChangeLocationDialog;
