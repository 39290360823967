import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  SearchDocumentsResponse,
  SearchDocumentsRequest,
  SEARCH_DOCUMENTS,
  DocumentSearchType
} from '../apollo/queries/searchDocuments';
import useDocuments, { DocumentData, DocumentType } from './useDocuments';

export const IMAGE_ORDER = [
  'Front',
  'NearSide',
  'OffSide',
  'Rear',
  'Boot',
  'CenterConsole',
  'FrontCab',
  'RearCab'
];

export const useVehicleImages = (orderNumber: string) => {
  const [images, setImages] = useState<
    Array<{ type: DocumentType; subType: string; url: string; dateCreated: string }>
  >([]);
  const { fetchDocumentLink } = useDocuments();

  const [fetchSingle] = useLazyQuery<SearchDocumentsResponse, SearchDocumentsRequest>(
    SEARCH_DOCUMENTS
  );

  const [fetchExterior, { loading: isLoadingExterior }] = useLazyQuery<
    SearchDocumentsResponse,
    SearchDocumentsRequest
  >(SEARCH_DOCUMENTS, {
    ssr: true,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        searchVal: orderNumber,
        searchType: DocumentSearchType.ORDER_NUMBER,
        documentType: 'PhotoExterior'
      }
    },
    onCompleted: async res => {
      if (res.documents.length > 0) {
        const latestOfEach = Object.values(
          res.documents.reduce((acc: { [key: string]: DocumentData }, curr: DocumentData) => {
            if (
              !acc[curr.subType ?? ''] ||
              new Date(curr.dateCreated) > new Date(acc[curr.subType ?? ''].dateCreated)
            ) {
              acc[curr.subType ?? ''] = curr;
            }
            return acc;
          }, {})
        );
        latestOfEach.forEach(async d => {
          const imageUrl = (await fetchDocumentLink(d.documentId, false)) ?? '';
          setImages(prev => [
            ...prev,
            {
              type: 'PhotoExterior',
              subType: d.subType ?? '',
              url: imageUrl,
              dateCreated: d.dateCreated
            }
          ]);
        });
      }
    }
  });
  const [fetchInterior, { loading: isLoadingInterior }] = useLazyQuery<
    SearchDocumentsResponse,
    SearchDocumentsRequest
  >(SEARCH_DOCUMENTS, {
    ssr: true,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        searchVal: orderNumber,
        searchType: DocumentSearchType.ORDER_NUMBER,
        documentType: 'PhotoInterior'
      }
    },
    onCompleted: async res => {
      if (res.documents.length > 0) {
        const latestOfEach = Object.values(
          res.documents.reduce((acc: { [key: string]: DocumentData }, curr: DocumentData) => {
            if (
              !acc[curr.subType ?? ''] ||
              new Date(curr.dateCreated) > new Date(acc[curr.subType ?? ''].dateCreated)
            ) {
              acc[curr.subType ?? ''] = curr;
            }
            return acc;
          }, {})
        );
        latestOfEach.forEach(async d => {
          const imageUrl = (await fetchDocumentLink(d.documentId, false)) ?? '';
          setImages(prev => [
            ...prev,
            {
              type: 'PhotoInterior',
              subType: d.subType ?? '',
              url: imageUrl,
              dateCreated: d.dateCreated
            }
          ]);
        });
      }
    }
  });

  const [fetchEquipment, { loading: isLoadingEquipment }] = useLazyQuery<
    SearchDocumentsResponse,
    SearchDocumentsRequest
  >(SEARCH_DOCUMENTS, {
    ssr: true,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        searchVal: orderNumber,
        searchType: DocumentSearchType.ORDER_NUMBER,
        documentType: 'PhotoEquipment'
      }
    },
    onCompleted: async res => {
      if (res.documents.length > 0) {
        const latestOfEach = Object.values(
          res.documents.reduce((acc: { [key: string]: DocumentData }, curr: DocumentData) => {
            if (
              !acc[curr.subType ?? ''] ||
              new Date(curr.dateCreated) > new Date(acc[curr.subType ?? ''].dateCreated)
            ) {
              acc[curr.subType ?? ''] = curr;
            }
            return acc;
          }, {})
        );
        latestOfEach.forEach(async d => {
          const imageUrl = (await fetchDocumentLink(d.documentId, false)) ?? '';
          setImages(prev => [
            ...prev,
            {
              type: 'PhotoEquipment',
              subType: d.subType ?? '',
              url: imageUrl,
              dateCreated: d.dateCreated
            }
          ]);
        });
      }
    }
  });

  const fetch = () => {
    fetchExterior();
    fetchInterior();
    fetchEquipment();
  };

  const refetchImage = (docType: DocumentType, subType: string, onCompleted: () => void) => {
    fetchSingle({
      variables: {
        input: {
          searchVal: orderNumber,
          searchType: DocumentSearchType.ORDER_NUMBER,
          documentType: docType,
          subType: subType
        }
      },
      fetchPolicy: 'no-cache',
      onCompleted: async data => {
        if (data.documents.length > 0) {
          const foundImg = data.documents
            .slice()
            .sort(
              (a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
            )[0];

          await fetchDocumentLink(foundImg.documentId, false).then(url => {
            setImages(prev => [
              ...prev.filter(img => img.subType !== subType),
              {
                type: foundImg.documentType,
                subType: foundImg.subType ?? '',
                url: url ?? '',
                dateCreated: foundImg.dateCreated
              }
            ]);
            onCompleted();
          });
        }
      }
    });
  };

  return {
    fetch,
    images: images.sort((a, b) => IMAGE_ORDER.indexOf(a.subType) - IMAGE_ORDER.indexOf(b.subType)),
    loading: isLoadingExterior || isLoadingInterior || isLoadingEquipment,
    setImages,
    refetchImage
  };
};
