import { useState, useCallback, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  groupExtras,
  orderlineStatusToOrderlineEventMap as orderlineStatusToOrderlineEvent
} from '~app/helpers/orderHelper';
import { ExtraType } from '~app/helpers/handoverConverter';
import {
  OrderStatusEventName,
  SendExtraStatusUpdateToPomsRequest,
  SendExtraStatusUpdateToPomsResponse,
  SEND_EXTRA_STATUS_UPDATE_TO_POMS
} from '~app/apollo/mutations/sendExtraStatusUpdateToPoms';
import {
  GetOrderResponse,
  GetOrderParams,
  GET_ORDER,
  OrderContract
} from '~app/apollo/queries/getOrder';

const useSendExtraStatus = (onCompleted?: () => void) => {
  const [pendingExtraStatusUpdate, setPendingExtraStatusUpdate] = useState<{
    extras: ReturnType<typeof groupExtras>;
    status: OrderStatusEventName;
  } | null>(null);
  const [isPolling, setIsPolling] = useState(false);

  const extraStatusCheck = useCallback(
    (order: OrderContract) => {
      if (pendingExtraStatusUpdate == null) {
        return true;
      }
      if (order.data == null) {
        return false;
      }
      // TODO: do we want to check parts as well?
      const t = (pendingExtraStatusUpdate.extras.get(order.data?.order_no ?? '') ?? []).map(
        x => x.orderLineId
      );
      const extrasToUpdate =
        order.data.orderlines?.filter(x => t.includes(x.orderline_id ?? 0)) ?? [];
      return extrasToUpdate.every(
        x =>
          x.orderline_status &&
          orderlineStatusToOrderlineEvent.get(x.orderline_status) ===
            pendingExtraStatusUpdate.status
      );
    },
    [pendingExtraStatusUpdate]
  );

  const [getOrder, { data: orderData, stopPolling, startPolling }] = useLazyQuery<
    GetOrderResponse,
    GetOrderParams
  >(GET_ORDER, {
    fetchPolicy: 'network-only',
    pollInterval: 2000
  });
  const [sendToPoms, { error, loading }] = useMutation<
    SendExtraStatusUpdateToPomsResponse,
    SendExtraStatusUpdateToPomsRequest
  >(SEND_EXTRA_STATUS_UPDATE_TO_POMS);

  useEffect(() => {
    if (error && stopPolling) {
      stopPolling();
      setIsPolling(false);
    }
  }, [error, stopPolling]);

  useEffect(() => {
    if (orderData) {
      const parsedOrder: OrderContract = JSON.parse(orderData.order);
      if (
        stopPolling &&
        pendingExtraStatusUpdate?.extras.keys().next().value === parsedOrder.data?.order_no &&
        extraStatusCheck(parsedOrder)
      ) {
        stopPolling();
        pendingExtraStatusUpdate?.extras.delete(parsedOrder.data?.order_no ?? '');
        setPendingExtraStatusUpdate(pendingExtraStatusUpdate);
        if (pendingExtraStatusUpdate && pendingExtraStatusUpdate.extras.size > 0) {
          getOrder({
            variables: {
              input: {
                orderNumber: pendingExtraStatusUpdate.extras.keys().next().value
              }
            }
          });
          startPolling(2000);
        } else {
          setIsPolling(false);
          onCompleted && onCompleted();
        }
      }
    }
    // TODO: How to include pendingExtraStatusUpdate?
  }, [
    extraStatusCheck,
    orderData,
    pendingExtraStatusUpdate,
    getOrder,
    startPolling,
    stopPolling,
    onCompleted
  ]);

  const sendExtraStatusUpdate = useCallback(
    (
      orderlineIds: Array<number | null>,
      winterTireMount: boolean,
      status: OrderStatusEventName,
      extras: Array<ExtraType> = []
    ) => {
      setIsPolling(true);
      const packagesToUpdate = groupExtras(
        extras.filter(x => x.orderLineId != null && orderlineIds.includes(x.orderLineId)),
        winterTireMount
      );
      setPendingExtraStatusUpdate({ status: status, extras: packagesToUpdate });
      Array.from(packagesToUpdate.keys()).forEach(ordernumber => {
        const orderPackages = packagesToUpdate.get(ordernumber)?.filter(x => x != null) ?? [];
        const orderPartIds = orderPackages.map(x =>
          x.parts.filter(x => x != null && x.orderLineId != null).map(y => y.orderLineId as number)
        );
        const allOrderlineIds = new Array<number>().concat(
          ...orderPackages.map(x => x.orderLineId as number), // Safe cast since previouse filter
          ...orderPartIds
        );
        sendToPoms({
          variables: {
            input: {
              orderNumber: ordernumber,
              orderLines: allOrderlineIds,
              orderEventName: status
            }
          }
        });
      });
      getOrder({
        variables: {
          input: { orderNumber: packagesToUpdate.keys().next().value }
        }
      });
      startPolling(2000);
    },
    [getOrder, sendToPoms, startPolling]
  );

  return {
    sendExtraStatusUpdate,
    isUpdating: loading || isPolling
  };
};

export default useSendExtraStatus;
