import styled from '@emotion/styled';
import { Text, Colors } from '@polestar/component-warehouse-react';

export const IconOnly = styled.div<{
  borderColor: string;
  background?: string;
  isExpanded?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  padding-left: ${props => (props.isExpanded ? '16px' : null)};
  padding-right: ${props => (props.isExpanded ? '16px' : null)};
  height: 32px;
  border: solid 1px ${props => props.borderColor};
  border-radius: 50px;
  background: ${props => (props.background ? props.background : Colors.white)};
  font-size: 14px;
  min-width: ${props => (props.isExpanded ? '112px' : '32px')};
  max-width: ${props => (props.isExpanded ? null : '32px')};
  width: fit-content;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;

export const ValueWithIcon = styled.div<{
  borderColor: string;
  background?: string;
  isExpanded?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  padding-left: ${props => (props.isExpanded ? '12px' : null)};
  padding-right: ${props => (props.isExpanded ? '16px' : null)};
  height: 32px;
  border: solid 1px ${props => props.borderColor};
  border-radius: 50px;
  background: ${props => (props.background ? props.background : Colors.white)};
  font-size: 14px;
  min-width: 32px;
  max-width: ${props => (props.isExpanded ? null : '32px')};
  width: fit-content;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;

export const ValueIconText = styled(Text)<{ withMargin?: boolean }>`
  margin-left: ${props => (props.withMargin ? '12px' : '0px')};
  font-size: 14px;
  padding-top: 2px;
  overflow-wrap: normal;
  white-space: nowrap;
`;
