export enum TableCells {
  CUSTOMER_CELL = 'Customer',
  VIN_CELL = 'VIN',
  REGISTRATION_NUMBER_CELL = 'RegistrationNumber',
  HANDOVER_TIME_SLOT_CELL = 'HandoverTimeSlot',
  HANDOVER_TIMEZONE = 'HandoverTimezone',
  BOOKING_STATUS_CELL = 'BookingStatus',
  VEHICLE_PREPARATION_STATE_CELL = 'CarReady',
  PDI_LABEL_SHORT_CELL = 'PDILabelShort',
  EXTRAS_CELL = 'Extras',
  EXTRAS_STATUS_CELL = 'ExtrasStatus',
  PDS_LABEL_SHORT_CELL = 'PDSLabelShort',
  ASSIGNED_TO_CELL = 'AssignedTo',
  ASSIGNED_TO_USER_ID_CELL = 'AssignedToUserId',
  REGISTRATION_CELL = 'Registration',
  REGISTRATION_STATUS_CELL = 'RegistrationStatus',
  REGISTRATION_DATE_CELL = 'RegistrationDate',
  LOCK_STATE_CELL = 'LockState',
  FINANCE_METHOD_CELL = 'FinanceMethod',
  ORDER_TYPE_CELL = 'OrderType',
  DELIVERY_LOCATION_CELL = 'DeliveryLocation',
  PDI_PDS_LOCATION_CELL = 'PdiPdsLocation',
  ORDER_ID_CELL = 'OrderID',
  HANDOVER_METHOD_CELL = 'HandoverMethod',
  ESTIMATED_ARRIVAL_DATE_CELL = 'EstimatedArrivalDate',
  DEFAULT_BRAND_STATUS_CELL = 'VISTAStatus',
  VISTA_BRAND_STATUS_UPDATED_AT_CELL = 'VISTAStatusUpdatedAt',
  MARKET_ADAPTION_CELL = 'MarketAdaption',
  MARKET_ADAPTION_DATE_CELL = 'MarketAdaptationDate',
  EMEA_BRAND_STATUS_CELL = 'EmeaBrandStatus',
  MARKET_CELL = 'Market',
  FULLY_PAID_CELL = 'FullyPaid',
  REG_RESPONSIBILITY_CELL = 'RegResponsibility',
  CHARGING_CELL = 'Charging',
  REGISTRATION_PARTNER_STATUS_CELL = 'RegistrationPartnerStatus',
  ALERTS_CELL = 'Alerts',
  NOTES_COUNT_CELL = 'NotesCount',
  NOTES_COMMENTS_CELL = 'NotesComments',
  BATCH_ID_CELL = 'BatchID',
  PICKUP_DATE_CELL = 'PickupDate',
  CONTACT_PERSON_CELL = 'ContactPerson',
  EDITION = 'Edition',
  BUYER = 'Buyer',
  HANDOVER_PREPARATION_CELL = 'HandoverPreparation',
  HAS_THUMBS_UP = 'HasThumbsUp',
  FEEDBACK_COMMENT = 'FeedbackComment',
  COMPLETED_AT = 'CompletedAt',
  COMMERCIAL_DELIVERY_HOLD_STATUS = 'CommercialDeliveryHoldStatus',
  MODEL = 'Model',
  MODEL_YEAR = 'ModelYear',
  PO_NUMBER = 'PurchaseOrderNumber'
}
