import { isNullishOrEmpty, isNonEmptyString } from './stringHelper';
import {
  OrderContract,
  OrderLine,
  OrderlineStatus,
  TOW_BAR_PART_NR,
  TOW_BAR_SALES_ITEM_ID
} from '~app/apollo/queries/getOrder';
import { ExtrasContract } from '~app/apollo/queries/getHandoverExtras';
import { DNB_ORG_NUMBERS, VOLVO_ORG_NUMBERS } from './orderHelper';

export const LEGACY_TRAILER_MODULE = '32369968';
export const UPDATED_TRAILER_MODULE = '32414148';

type HandoverMethod =
  | 'collect'
  | 'home_delivery'
  | 'paid_home_delivery'
  | 'delivery_at_car_handover'
  | '';
type LockStatus = 'unlocked' | 'locked' | 'manually_unlocked' | '';
export const SOCIAL_SECURITY_NUMBER = 'social_security_number';
export const ORGANISATION_NUMBER = 'organisation_number';
type IdType = typeof SOCIAL_SECURITY_NUMBER | typeof ORGANISATION_NUMBER | '';

export type AddressInfo = {
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressLine3?: string | null;
  suburb?: string | null;
  stateCode?: string | null;
  country?: string | null;
  postalCode?: string | null;
};

type OwnerType = {
  name: string;
  id: string;
  idType: IdType;
  gender?: string | null;
  dateOfBirth?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  driversLicenseNumber?: string | null;
  driversLicenseExpiryDate?: string | null;
  customerNumber?: string | null;
  residentialAddress?: AddressInfo;
  mailingAddress?: AddressInfo;
};

export type DriverType = {
  name: string;
  ssnumber: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  district: string;
  province: string;
  company: string | undefined;
  orgnumber: string | undefined;
};

export type FinanceType =
  | 'Cash'
  | 'Loan'
  | 'Loanballoon'
  | 'LoanBalloonGFV'
  | 'Leasing'
  | 'Subscription'
  | 'Personal Loan'
  | 'Personal Leasing'
  | 'Fleet'
  | 'OperationalLease'
  | '';

export type OrderType = 'pre-order' | 'order' | 'extras-order' | 'fleet' | 'remarketed' | '';
export const MARKET_LEVEL_1 = 'level_1';

export const TOW_BAR_ORDERLINE_ID_PHASE_ONE = null;

const hasTowBar = (orderlines: Array<OrderLine>) => {
  // Phase one
  // Towbar cannot be ordered as an Extra so this is the only place where we have the towbar info 2020-06-01 ho-portal_vs_poms channel
  const allCodes = orderlines.map(x => x.car_configuration?.package_codes ?? []);
  const packageCodes = new Array<string>().concat(...allCodes);
  const towBarAsPackage = packageCodes.some(x => x === 'A00271' || x === TOW_BAR_PART_NR) ?? false;
  return towBarAsPackage || /* Phase two */ orderlines.some(x => x.extra_product_type === 'Towbar');
};

type DeliveryPreferencies = {
  wallboxinstallation_delivery_preferencies?: {
    installation_at?: string | null;
    installation_partner?: string | null;
    pre_inspection_at?: string | null;
  };
  tirehotel_delivery_preferencies?: {
    transport_preference?: string | null;
    preferred_location?: string | null;
    preferred_location_name?: string | null;
    preferred_parma_code?: string | null;
    partner_id?: string | null;
  };
  wintertire_delivery_preferencies?: {
    wants_together_with_car?: boolean | null;
    wants_transport_from_dealer?: string | null;
  };
};

interface ExtraMapper {
  id: string;
  fallbackName: string;
  quantity: number;
  orderLineId: number | null;
  status: OrderlineStatus;
  deliveryDate: string | null | undefined;
  extraProductType: string | null | undefined;
  deliveryPreferencies: DeliveryPreferencies | null | undefined;
  salesItemId: number | null;
}

export interface ExtraType extends ExtraMapper {
  parts: Array<ExtraMapper>;
  orderNumber: string;
}

export const extraMapper = (extra: {
  part_nr?: string | null;
  local_name?: string | null;
  name?: string | null;
  quantity?: number | null;
  orderline_id?: number | null;
  orderline_status?: OrderlineStatus | null;
  delivery_date?: string | null;
  extra_product_type?: string | null;
  extra_delivery_preferencies?: DeliveryPreferencies | null;
  sales_item_id?: number | null;
}) => {
  return {
    id: extra.part_nr ?? '',
    fallbackName: isNonEmptyString(extra.local_name) ? extra.local_name : extra.name ?? '',
    quantity: extra.quantity ?? 0,
    orderLineId: extra.orderline_id ?? null,
    status: extra.orderline_status ?? 'pending',
    deliveryDate: extra.delivery_date,
    extraProductType: extra.extra_product_type,
    deliveryPreferencies: extra.extra_delivery_preferencies,
    salesItemId: extra.sales_item_id ?? null
  };
};

export const convertExtras = (orderlines: Array<OrderLine>, orderNumber: string) => {
  const packages = orderlines.filter(
    x =>
      x.orderline_status !== 'cancelled' &&
      x.orderline_type === 'Extra' &&
      x.extra_orderline_type === 'Package' &&
      x.extra_product_type !== 'Tax' &&
      isNonEmptyString(x.part_nr)
  );
  const products = orderlines.filter(
    x =>
      x.orderline_status !== 'cancelled' &&
      x.orderline_type === 'Extra' &&
      x.extra_orderline_type === 'Product' &&
      x.extra_product_type !== 'Tax'
  );
  return (
    packages.map(x => ({
      ...extraMapper(x),
      parts: products?.filter(y => y.package_id === x.part_nr).map(y => extraMapper(y)) ?? [],
      orderNumber: orderNumber
    })) ?? []
  );
};

export type Extra = ReturnType<typeof convertExtras>[number];
export type ExtraPart = ReturnType<typeof extraMapper>;

const getExtras = (
  orderlines: Array<OrderLine>,
  orderNumber: string,
  carOrderlineStatus: OrderlineStatus,
  carDeliveryDate: string | null,
  carChassisNumbers: string | null,
  carModelYear: string | null,
  relatedExtraOrders: Array<OrderContract>
) => {
  const extras = [...convertExtras(orderlines, orderNumber)].concat(
    ...relatedExtraOrders.map(x => convertExtras(x.data?.orderlines ?? [], x.data?.order_no ?? ''))
  );
  if (hasTowBar(orderlines)) {
    const towbarFoundInExtras = extras.some(x => x.extraProductType === 'Towbar');
    const commonProps: Pick<
      ReturnType<typeof extraMapper>,
      | 'orderLineId'
      | 'status'
      | 'deliveryDate'
      | 'quantity'
      | 'extraProductType'
      | 'deliveryPreferencies'
    > = {
      orderLineId: TOW_BAR_ORDERLINE_ID_PHASE_ONE,
      status: carOrderlineStatus,
      deliveryDate: carDeliveryDate,
      quantity: 1,
      extraProductType: 'Towbar',
      deliveryPreferencies: {}
    };

    /**
     * https://polestarjira.atlassian.net/browse/VH-3792
     * Vehicles produced after w46 will need to have a new trailer module part compared to those produced before w46.
     * The new module doesn't fit old cars and vice versa. We need to support both.
     * Chassis number 241130 and lower for 2024 and lower should have the old module.
     */
    const modelYear = parseInt(carModelYear ?? '');
    const chassisNumber = parseInt(carChassisNumbers ?? '');

    const trailerModuleId =
      modelYear <= 2024 && chassisNumber <= 241130 ? LEGACY_TRAILER_MODULE : UPDATED_TRAILER_MODULE;

    const towBarParts = [
      {
        id: '32207226',
        salesItemId: null,
        fallbackName: 'Semi Electrically Retractable Towbar',
        ...commonProps
      },
      { id: trailerModuleId, salesItemId: null, fallbackName: 'Trailer module', ...commonProps },
      {
        id: '31650476',
        salesItemId: null,
        fallbackName: 'Bracket, trailer module',
        ...commonProps
      },
      { id: '31399328', salesItemId: null, fallbackName: 'Operation button panel', ...commonProps },
      { id: '32296317', salesItemId: null, fallbackName: 'SW- TRM ADD 13S', ...commonProps }
    ];
    const towBarFallbackName = 'Towbar (retractable, semi electrical)';

    if (!towbarFoundInExtras) {
      extras.push({
        id: TOW_BAR_PART_NR,
        fallbackName: towBarFallbackName,
        orderNumber: orderNumber,
        ...commonProps,
        parts: towBarParts,
        salesItemId: TOW_BAR_SALES_ITEM_ID
      });
    } else {
      extras.forEach(extra => {
        if (extra.id === TOW_BAR_PART_NR) {
          extra.fallbackName = towBarFallbackName;
          extra.parts = towBarParts;
        } else if (extra.id === 'A01407') {
          extra.parts.push(
            {
              id: '32351966',
              salesItemId: null,
              fallbackName: 'Trailer module',
              ...commonProps
            },
            {
              id: '32417110',
              salesItemId: null,
              fallbackName: 'Tape for WAE',
              ...commonProps
            },
            {
              id: '32414297',
              salesItemId: null,
              fallbackName: 'SW- TRM ADD 13S',
              ...commonProps
            }
          );
        } else if (extra.id === 'A01408') {
          extra.parts.push(
            {
              id: '32351966',
              salesItemId: null,
              fallbackName: 'Trailer module',
              ...commonProps
            },
            {
              id: '32417110',
              salesItemId: null,
              fallbackName: 'Tape for WAE',
              ...commonProps
            },
            {
              id: '32266344',
              salesItemId: null,
              fallbackName: 'Switch pack',
              ...commonProps
            },
            {
              id: '32414297',
              salesItemId: null,
              fallbackName: 'SW- TRM ADD 13S',
              ...commonProps
            }
          );
        } else if (extra.id === 'A01409') {
          extra.parts.push(
            {
              id: '32351966',
              salesItemId: null,
              fallbackName: 'Trailer module',
              ...commonProps
            },
            {
              id: '32417110',
              salesItemId: null,
              fallbackName: 'Tape for WAE',
              ...commonProps
            },
            {
              id: '32132981',
              salesItemId: null,
              fallbackName: 'Ball holder w 2" ball',
              ...commonProps
            },
            {
              id: '32296756',
              salesItemId: null,
              fallbackName: 'Bag jack',
              ...commonProps
            },
            {
              id: '32414296',
              salesItemId: null,
              fallbackName: 'SW- TRM ADD 4/7S',
              ...commonProps
            }
          );
        } else if (extra.id === 'A01520') {
          extra.parts.push(
            {
              id: '80009169',
              salesItemId: null,
              fallbackName: 'Cable harness (TRM to towbar)',
              ...commonProps
            },
            {
              id: '80014770',
              salesItemId: null,
              fallbackName: 'Trailer module',
              ...commonProps
            },
            {
              id: '31207209',
              salesItemId: null,
              fallbackName: 'Fastener kit',
              ...commonProps
            },
            {
              id: '80001861',
              salesItemId: null,
              fallbackName: 'Switch pack',
              ...commonProps
            },
            {
              id: '80019136',
              salesItemId: null,
              fallbackName: 'SW- TRM ADD 13S',
              ...commonProps
            }
          );
        } else if (extra.id === 'A01521') {
          extra.parts.push(
            {
              id: '80009167',
              salesItemId: null,
              fallbackName: 'Cable harness (TRM to towbar)',
              ...commonProps
            },
            {
              id: '80014770',
              salesItemId: null,
              fallbackName: 'Trailer module',
              ...commonProps
            },
            {
              id: '31207209',
              salesItemId: null,
              fallbackName: 'Fastener kit',
              ...commonProps
            },
            {
              id: '80001861',
              salesItemId: null,
              fallbackName: 'Switch pack',
              ...commonProps
            },
            {
              id: '32296756',
              salesItemId: null,
              fallbackName: 'Bag jack',
              ...commonProps
            },
            {
              id: '80019135',
              salesItemId: null,
              fallbackName: 'SW- TRM ADD 4/7S',
              ...commonProps
            }
          );
        }
      });
    }
  }
  return extras;
};

export const convertOrder = (
  orderDetails: OrderContract,
  relatedExtraOrders: Array<OrderContract> = []
) => {
  const { data } = orderDetails;
  if (data == null) {
    throw new Error('No POMS data');
  }

  const isB2B = data.sales_type === 'B2B';
  const fspDataName = data.fsp_data?.name ?? ''; // this is the correct name according to meeting 2020/05/13
  const fspDataOrgNbr = data.fsp_data?.organization_number ?? '';
  const financeType =
    data?.order_type === 'fleet'
      ? 'Fleet'
      : data?.financial_information?.financial_product ?? ('' as FinanceType);

  const pomsDriver = data.person_data?.find(x => x.roles && x.roles.includes('driver'));
  const pomsOrderer = data.person_data?.find(x => x.roles && x.roles.includes('orderer'));
  const pomsOwners = data.person_data?.filter(x => x.roles && x.roles.includes('owner'));
  const additionalDeliveries = data.additional_deliveries;

  const companyData = {
    name: data.company_data?.name ?? '',
    orgnumber: data.company_data?.organization_number ?? '',
    address1: data.company_data?.address_line_1 ?? '',
    address2: data.company_data?.address_line_2 ?? '',
    zipCode: data.company_data?.zip_code ?? '',
    city: data.company_data?.city ?? '',
    district: '', // No such field on company data
    province: '' // No such field on company data
  };

  const getContractOwners = () => {
    let owners: OwnerType[] = [];
    if (registeredOwners.length > 0 && financeType === 'Loan' && shouldUseRegisteredOwner()) {
      owners = registeredOwners;
    } else if (
      // VH-1473
      registeredOwners.length > 0 &&
      (data.market?.toLowerCase() === 'dk' || // VH-1939
        (financeType === 'Cash' && data.market?.toLowerCase() === 'fi'))
    ) {
      owners = registeredOwners;
    } else if (pomsOwners && pomsOwners?.length > 0) {
      owners = [
        {
          name: `${pomsOwners[0].first_name ?? ''} ${pomsOwners[0].last_name ?? ''}`.trim(),
          id: pomsOwners[0].ssn ?? '',
          idType: SOCIAL_SECURITY_NUMBER
        }
      ];
    } else if (data.fsp_data && data.fsp_data.roles?.includes('owner')) {
      owners = [
        {
          name: fspDataName,
          id: fspDataOrgNbr,
          idType: ORGANISATION_NUMBER
        }
      ];
    } else if (data.company_data && data.company_data.roles?.includes('owner')) {
      owners = [
        {
          name: companyData.name,
          id: companyData.orgnumber,
          idType: ORGANISATION_NUMBER
        }
      ];
    }
    return owners;
  };

  const driver = {
    name: `${pomsDriver?.first_name ?? ''} ${pomsDriver?.last_name ?? ''}`.trim(),
    ssnumber: pomsDriver?.ssn ?? '',
    email: pomsDriver?.email ?? '',
    phone: pomsDriver?.phone ?? '',
    address1: pomsDriver?.address_line_1 ?? '',
    address2: pomsDriver?.address_line_2 ?? '',
    zipCode: pomsDriver?.zip_code ?? '',
    city: pomsDriver?.city ?? '',
    district: pomsDriver?.district ?? '',
    province: pomsDriver?.province ?? '',
    company: isB2B ? companyData.name : undefined,
    orgnumber: isB2B ? companyData.orgnumber : undefined
  };

  const owners =
    financeType === 'Leasing' ||
    financeType === 'Fleet' ||
    financeType === 'Subscription' ||
    financeType === 'OperationalLease'
      ? [
          {
            name: fspDataName,
            orgnumber: fspDataOrgNbr,
            ssnumber: '',
            address1: '',
            address2: '',
            zipCode: '',
            city: '',
            district: '',
            province: ''
          }
        ]
      : pomsOwners && pomsOwners.length > 0
      ? pomsOwners.map(x => ({
          name: `${x.first_name ?? ''} ${x.last_name ?? ''}`.trim(),
          ssnumber: x.ssn ?? '',
          orgnumber: '',
          address1: x.address_line_1 ?? '',
          address2: x.address_line_2 ?? '',
          zipCode: x.zip_code ?? '',
          city: x.city ?? '',
          district: x.district ?? '',
          province: x.province ?? ''
        }))
      : [{ ...companyData, ssnumber: '' }];

  const registeredOwners: Array<OwnerType> =
    data.registration?.registered_owners?.map(x => ({
      name: x.name ?? '',
      id: x.identification_number ?? '',
      idType: x.id_type === 'socialSecurityNumber' ? SOCIAL_SECURITY_NUMBER : ORGANISATION_NUMBER,
      dateOfBirth: x.date_of_birth,
      gender: x.gender,
      phoneNumber: x.phone_number,
      email: x.email,
      driversLicenseNumber: x.drivers_license_number,
      driversLicenseExpiryDate: x.drivers_license_expiry_date,
      customerNumber: x.customer_number,
      residentialAddress: x.residential_address
        ? {
            addressLine1: x.residential_address.address_line1,
            addressLine2: x.residential_address.address_line2,
            addressLine3: x.residential_address.address_line3,
            suburb: x.residential_address.suburb,
            stateCode: x.residential_address.state_code,
            country: x.residential_address.country,
            postalCode: x.residential_address.postal_code
          }
        : undefined,
      mailingAddress: x.mailing_address
        ? {
            addressLine1: x.mailing_address.address_line1,
            addressLine2: x.mailing_address.address_line2,
            addressLine3: x.mailing_address.address_line3,
            suburb: x.mailing_address.suburb,
            stateCode: x.mailing_address.state_code,
            country: x.mailing_address.country,
            postalCode: x.mailing_address.postal_code
          }
        : undefined
    })) ?? [];

  const registrationData = {
    fspRegistrationData: data.fsp_data
      ? { name: fspDataName, orgnumber: fspDataOrgNbr }
      : undefined,
    dkRegistrationData: {
      registrationTax: data.registration?.dk_registration_information?.registered_tax_value,
      importerRegistrationCode:
        data.registration?.dk_registration_information?.importer_registration_code
    },
    possessors: data.registration?.possessors,
    deRegistrationInformation: data.registration?.de_registration_information,
    certificateNumber: data.registration?.certificate_number, // VarmenneCode
    garageAddress: data.registration?.registration_details?.garage_address
      ? {
          typeOfUse: data.registration?.registration_details?.garage_address?.type_of_use,
          addressLine1: data.registration?.registration_details?.garage_address?.address_line1,
          addressLine2: data.registration?.registration_details?.garage_address?.address_line2,
          addressLine3: data.registration?.registration_details?.garage_address?.address_line3,
          suburb: data.registration?.registration_details?.garage_address?.suburb,
          stateCode: data.registration?.registration_details?.garage_address?.state_code,
          country: data.registration?.registration_details?.garage_address?.country,
          postalCode: data.registration?.registration_details?.garage_address?.postal_code
        }
      : undefined,
    concessionRequested: data.registration?.registration_details?.concession_requested
  };

  const wheelRelatedOrder = relatedExtraOrders.find(order =>
    order.data?.orderlines?.some(x => x.extra_product_type === 'Winter Wheels')
  );

  const pomsConsumerCar = data.orderlines?.find(x => x.orderline_type === 'ConsumerCar');
  const vehicle = {
    model: pomsConsumerCar?.car_configuration?.model ?? '',
    modelCode: data.cardata_for_extras?.model_code ?? '',
    color: pomsConsumerCar?.car_configuration?.colour ?? '',
    year:
      pomsConsumerCar?.car_configuration?.model_year ?? data.cardata_for_extras?.model_year ?? '',
    interior: pomsConsumerCar?.car_configuration?.upholstery ?? '',
    wheels: pomsConsumerCar?.car_configuration?.wheels_rims ?? '',
    performance: pomsConsumerCar?.car_configuration?.performance,
    winterTireMount: wheelRelatedOrder
      ? wheelRelatedOrder.data?.handover_booking?.needs_winter_wheel_mounting ?? false
      : data.handover_booking?.needs_winter_wheel_mounting ?? false,
    deliveryDate: pomsConsumerCar?.delivery_date,
    vin: data.cardata_for_extras?.vin ?? '',
    powerTrain: pomsConsumerCar?.car_configuration?.engine ?? '',
    packages: pomsConsumerCar?.car_configuration?.packages,
    edition: pomsConsumerCar?.car_configuration?.edition ?? null,
    orderlineStatus: pomsConsumerCar?.orderline_status ?? null,
    exteriorImageUrl: pomsConsumerCar?.car_configuration?.exterior_image_url ?? null
  };

  const extras = getExtras(
    data.orderlines?.filter(orderline => orderline.orderline_status !== 'cancelled') ?? [],
    data.order_no ?? '',
    pomsConsumerCar?.orderline_status ?? 'confirmed',
    pomsConsumerCar?.delivery_date ?? null,
    pomsConsumerCar?.car_factory_order?.chassis_number ?? null,
    pomsConsumerCar?.car_configuration?.model_year ?? null,
    relatedExtraOrders
  );

  // TODO Can be multiple drivers
  const getContractDriver = () => {
    let contractDriver: OwnerType = { name: '', id: '', idType: '' };

    if (registeredOwners.length > 0 && financeType === 'Leasing' && shouldUseRegisteredOwner()) {
      contractDriver = registeredOwners[0];
    } else if (data.person_data?.find(x => x.roles && x.roles.includes('buyer'))) {
      const foundBuyer = data.person_data?.find(x => x.roles && x.roles.includes('buyer'));
      contractDriver = {
        name: `${pomsDriver?.first_name ?? ''} ${pomsDriver?.last_name ?? ''}`.trim() ?? '',
        id: foundBuyer?.ssn ?? '',
        idType: SOCIAL_SECURITY_NUMBER
      };
    } else if (data.company_data?.roles?.includes('buyer')) {
      const foundBuyer = data.company_data;
      contractDriver = {
        name: foundBuyer.name ?? '',
        id: foundBuyer.organization_number ?? '',
        idType: ORGANISATION_NUMBER
      };
    } else if (data.fsp_data?.roles?.includes('buyer')) {
      const foundBuyer = data.fsp_data;
      contractDriver = {
        name: foundBuyer.name ?? '',
        id: foundBuyer.organization_number ?? '',
        idType: ORGANISATION_NUMBER
      };
    }

    return contractDriver;
  };

  const shouldUseRegisteredOwner = () => {
    return (
      (data.market?.toLowerCase() === 'se' && VOLVO_ORG_NUMBERS.includes(fspDataOrgNbr)) ||
      (data.market?.toLowerCase() === 'no' && DNB_ORG_NUMBERS.includes(fspDataOrgNbr))
    );
  };

  return {
    ...{
      market: data?.market ?? 'SE',
      orderNumber: data.order_no ?? '',
      orderType: data.order_type ?? ('' as OrderType),
      lockState: data.lock_state ?? ('' as LockStatus),
      language: isNonEmptyString(data.iso_language_code) ? <string>data.iso_language_code : 'en-GB',
      orderToDeliveryFlow: data.order_to_delivery_flow,
      logisticFlow: data.logistic_flow
    },
    ...{
      totalPrice: data.financial_information?.total_price,
      isFullyPaid: data.financial_information?.final_payment?.buyer?.status === 'fully_paid',
      isOtherPaymentSigned:
        data.financial_information?.other_payment?.other_payment_status === 'signed',
      isFinalPaymentPaidToExternalPartner:
        data.financial_information?.final_payment?.buyer?.paid_final_payment_to_external_partner,
      upfrontPayment: data.financial_information?.other_payment?.upfront_payment,
      amountPaid: data.financial_information?.final_payment?.buyer?.amount_paid,
      amountPaidByBuyer: data.financial_information?.final_payment?.buyer?.amount_paid_by_buyer,
      creditTerm: data.financial_information?.other_payment?.terms ?? null,
      chFSPName: data.financial_information?.other_payment?.financial_service_provider,
      personalFinancingStatus: data.financial_information?.personal_financing?.status,
      applicationId: data.financial_information?.other_payment?.financing_application_id,
      carValue: data.financial_information?.car_total_price,
      grandTotalCarExtrasDeliveryBasicPriceInclVat:
        data.financial_information?.grand_total_car_extras_delivery_basic_price_incl_vat,
      leasingCompany: data.financial_information?.other_payment?.financial_service_provider
    },
    salesContractStatus:
      data.sales_contract?.status === '' || !data.sales_contract?.status
        ? 'open'
        : data.sales_contract?.status,

    fleetReferenceNumber: data.fleet?.reference_number ?? '',
    fleetManagementCompanyData: data.fleet_management_company_data,
    ...{
      registrationNumber: data.registration?.licence_plate ?? '',
      registrationDate: data.registration?.registration_date ?? '',
      insuranceType: data.registration?.registration_details?.insurance_type,
      importerRegistrationCodeDK:
        data.registration?.dk_registration_information?.importer_registration_code,
      stammnummer: data.registration?.vehicle_serial_number,
      documents: data.registration?.registration_details?.documents ?? [],
      contractReferenceNumber:
        data.registration?.registration_details?.contract_reference_number ?? '',
      broker: [
        data.registration?.registration_details?.broker_name,
        data.registration?.registration_details?.broker_email,
        data.registration?.registration_details?.broker_phone_number
      ].every(x => isNullishOrEmpty(x))
        ? null
        : {
            name: data.registration?.registration_details?.broker_name ?? '',
            email: data.registration?.registration_details?.broker_email ?? '',
            phone: data.registration?.registration_details?.broker_phone_number ?? ''
          }
    },
    requestedInsurance: {
      insuranceType: data.registration?.requested_insurance?.insurance_type,
      insuranceOption: data.registration?.requested_insurance?.insurance_option,
      insuranceProvider: data.registration?.requested_insurance?.insurance_provider,
      insurancePolicyHolder: data.registration?.requested_insurance?.insurance_policy_holder,
      otherInformation: data.registration?.requested_insurance?.other_information
    },
    ...{
      plannedDeliveryDate: data.handover_booking?.planned_delivery_date ?? '',
      insurance: data.handover_booking?.insurance ?? '',
      isPolestarInsuranceConfirmed: data.handover_booking?.polestar_insurance_confirmed ?? false,
      handoverMethod: <HandoverMethod>data.handover_booking?.handover_method ?? ''
    },
    ...registrationData,
    subsityData: {
      evSubsidyStatus: data.ev_subsidy_information?.ev_subsidy_status,
      evSubsidyAllocationDate: data.ev_subsidy_information?.ev_subsidy_allocation_date,
      evSubsidyAmount: data.ev_subsidy_information?.ev_subsidy_amount,
      evSubsidyConfirmationDate: data.ev_subsidy_information?.ev_subsidy_confirmation_date,
      evSubsidyCancellationDate: data.ev_subsidy_information?.ev_subsidy_cancellation_date
    },
    isB2B,
    financeType,
    fspDataName,
    driver,
    owners,
    registeredOwners,
    vehicle,
    extras,
    bookingDates: {
      earliestHandoverDate: data.handover_booking?.customer_required_earliest_handover_date ?? '',
      acceptedHandoverTime: {
        startDateTime: data.handover_booking?.accepted_handover_time?.start ?? '',
        endDateTime: data.handover_booking?.accepted_handover_time?.end ?? ''
      },
      suggestedHandoverTimes:
        data.handover_booking?.suggested_handover_times?.map(x => ({
          startDateTime: x.start ?? '',
          endDateTime: x.end ?? ''
        })) ?? [],
      suggestedHandoverTimesAt: data.handover_booking?.suggested_handover_times_at ?? '',
      handoverTimeStatus: data.handover_booking?.handover_time_status ?? '',
      handoverTimeStatusAt: data.handover_booking?.handover_time_status_at ?? '',
      rejectionNote: data.handover_booking?.customer_rejection_notes ?? ''
    },

    pickupPerson: data.handover_booking?.authorised_to_pick_up_the_car
      ? {
          name: data.handover_booking.authorised_to_pick_up_the_car.name ?? '',
          id: data.handover_booking.authorised_to_pick_up_the_car.SSN ?? ''
        }
      : { name: driver.name, id: driver.ssnumber },
    authorisedToPickUpTheCar: data.handover_booking?.authorised_to_pick_up_the_car,
    handoverLocationAddress: {
      address: data.handover_booking?.handover_location?.address_line_1 ?? '',
      zipAdress: `${data.handover_booking?.handover_location?.zip_code ?? ''} ${
        data.handover_booking?.handover_location?.city ?? ''
      }`,
      code: data.handover_booking?.handover_location?.code ?? '',
      name: data.handover_booking?.handover_location?.name ?? '',
      countryCode: data.handover_booking?.handover_location?.country_code ?? '',
      zipCode: data.handover_booking?.handover_location?.zip_code ?? '',
      city: data.handover_booking?.handover_location?.city ?? ''
    },

    notes: {
      handoverNotes: data.handover_booking?.handover_notes ?? '',
      fleetNotes: data.handover_booking?.fleet_notes ?? ''
    },

    companyVAT: data.customer_data?.b2b_consumer?.company_vat_num ?? '',
    additionalDeliveries,

    contractCompanyDataAddress: {
      address: companyData.address1,
      zipAdress: companyData.zipCode,
      city: companyData.city
    },

    contractOrdererAddress: {
      address: pomsOrderer?.address_line_1 ?? '',
      zipAdress: `${pomsOrderer?.zip_code ?? ''} ${pomsOrderer?.city ?? ''}`
    },

    contractOwners: getContractOwners(),

    contractCustomer: isB2B
      ? { name: companyData.name, id: companyData.orgnumber, idType: ORGANISATION_NUMBER }
      : (data.market === 'SE' || data.market === 'se') && registeredOwners.length === 1
      ? registeredOwners[0]
      : { name: driver.name, id: driver.ssnumber, idType: SOCIAL_SECURITY_NUMBER },

    contractDriver: getContractDriver(),

    remarketedCar: {
      features: data.remarketed_car?.features
    }
  };
};

export const convertExtrasContract = (extrasData: ExtrasContract) => {
  const { data } = extrasData;
  if (data === undefined) {
    return new Map<number, { imageUrl: string; name: string }>();
  }
  const mappedList = data.sales_categories.map(category =>
    category.sales_items.map(item => ({
      id: item.id,
      imageUrl: item.featured_image_url,
      name: item.title
    }))
  );
  return new Map(
    new Array<{ id: number; imageUrl: string; name: string }>()
      .concat(...mappedList)
      .map(x => [x.id, { imageUrl: x.imageUrl, name: x.name }])
  );
};

export const getConvertedOrderMock = (
  data: Partial<ReturnType<typeof convertOrder>> = {}
): ReturnType<typeof convertOrder> => ({
  driver: {
    name: '',
    ssnumber: '',
    email: '',
    phone: '',
    company: undefined,
    orgnumber: undefined,
    address1: '',
    address2: '',
    zipCode: '',
    city: '',
    district: '',
    province: ''
  },
  owners: [
    {
      address1: '',
      address2: '',
      city: '',
      district: '',
      name: '',
      orgnumber: '',
      province: '',
      ssnumber: '',
      zipCode: ''
    }
  ],
  ...{
    market: '',
    orderNumber: '',
    plannedDeliveryDate: '',
    lockState: 'unlocked',
    isFullyPaid: false,
    isFinalPaymentPaidToExternalPartner: false,
    salesContractStatus: 'open',
    isOtherPaymentSigned: false,
    personalFinancingStatus: undefined,
    fspDataName: '',
    fspDataOrgNbr: '',
    totalPrice: 0,
    applicationId: '0123',
    upfrontPayment: 0,
    amountPaid: 0,
    amountPaidByBuyer: 0,
    orderType: 'order',
    subsityData: {
      evSubsidyStatus: '',
      evSubsidyAllocationDate: '',
      evSubsidyAmount: '',
      evSubsidyConfirmationDate: '',
      evSubsidyCancellationDate: ''
    },
    isB2B: false,
    creditTerm: null,
    stammnummer: null,
    registrationDate: '',
    registrationNumber: '',
    contractReferenceNumber: '',
    insurance: 'personal',
    isPolestarInsuranceConfirmed: false,
    broker: null,
    leasingCompany: '',
    insuranceType: '',
    importerRegistrationCodeDK: '',
    carValue: 300,
    grandTotalCarExtrasDeliveryBasicPriceInclVat: 400
  },
  additionalDeliveries: [],
  vehicle: {
    model: '',
    modelCode: '534',
    color: '',
    year: '',
    interior: '',
    wheels: '',
    performance: '',
    winterTireMount: false,
    deliveryDate: null,
    vin: '',
    packages: [],
    powerTrain: '',
    edition: null,
    orderlineStatus: null,
    exteriorImageUrl: null
  },
  extras: [],
  bookingDates: {
    earliestHandoverDate: '',
    acceptedHandoverTime: { startDateTime: '', endDateTime: '' },
    suggestedHandoverTimes: [],
    handoverTimeStatus: '',
    suggestedHandoverTimesAt: '',
    rejectionNote: '',
    handoverTimeStatusAt: ''
  },
  fspRegistrationData: undefined,
  certificateNumber: '',
  garageAddress: {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    postalCode: '',
    stateCode: '',
    suburb: '',
    typeOfUse: ''
  },
  concessionRequested: undefined,
  dkRegistrationData: { importerRegistrationCode: '', registrationTax: '' },
  possessors: [],
  deRegistrationInformation: undefined,
  handoverLocationAddress: {
    address: '',
    zipAdress: '',
    code: '',
    name: '',
    countryCode: '',
    zipCode: '',
    city: ''
  },
  contractCompanyDataAddress: { address: '', zipAdress: '', city: '' },
  contractOrdererAddress: { address: '', zipAdress: '' },
  handoverMethod: '',
  notes: { handoverNotes: '', fleetNotes: '' },
  companyVAT: '',
  pickupPerson: { id: '', name: '' },
  authorisedToPickUpTheCar: {
    name: undefined,
    SSN: undefined
  },
  contractOwners: [],
  contractCustomer: { id: '', name: '', idType: '' },
  contractDriver: { name: '', id: '', idType: '' },
  language: 'en-GB',
  orderToDeliveryFlow: undefined,
  logisticFlow: undefined,
  chFSPName: undefined,
  financeType: '',
  registeredOwners: [],
  fleetReferenceNumber: '',
  fleetManagementCompanyData: { organization_number: '' },
  documents: [],
  requestedInsurance: {
    insuranceType: undefined,
    insuranceOption: undefined,
    insuranceProvider: undefined,
    insurancePolicyHolder: undefined,
    otherInformation: undefined
  },
  remarketedCar: {
    features: undefined
  },
  ...data
});
