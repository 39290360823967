import { useContext } from 'react';
import { HandoverContext } from '~app/contexts/Handover';

const useHandoverState = () => {
  const context = useContext(HandoverContext);
  if (context === undefined) {
    throw new Error('Handover context not defined');
  }
  return context;
};

export default useHandoverState;
