import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const SEND_EXTRA_STATUS_UPDATE_TO_POMS = gql`
  mutation sendExtraStatusUpdateToPoms($input: ExtrasOrderDeliveredRequest!) {
    isSuccess: sendExtrasOrderDelivered(input: $input)
  }
`;

export interface SendExtraStatusUpdateToPomsResponse {
  isSuccess: boolean;
}

export type OrderStatusEventName =
  | 'ExtrasOrderDelivery_completed'
  | 'ExtrasOrderDelivery_ordered'
  | 'ExtrasOrderDelivery_deliveryPlanning'
  | 'ExtrasOrderDelivery_readyForDelivery';

export interface SendExtraStatusUpdateToPomsRequest {
  input: {
    orderNumber: string;
    orderLines: Array<number>;
    orderEventName: OrderStatusEventName | null;
  };
}

export const getSendExtraStatusUpdateToPomsApolloMock = (
  request: SendExtraStatusUpdateToPomsRequest,
  result: SendExtraStatusUpdateToPomsResponse = { isSuccess: true }
): {
  request: { query: DocumentNode; variables: SendExtraStatusUpdateToPomsRequest };
  result: { data: SendExtraStatusUpdateToPomsResponse };
} => ({
  request: {
    query: SEND_EXTRA_STATUS_UPDATE_TO_POMS,
    variables: request
  },
  result: {
    data: result
  }
});
