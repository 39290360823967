import {
  Spacings,
  TableCell,
  Row,
  Column,
  Text,
  Image,
  Paragraph,
  Colors,
  Heading,
  Breakpoints,
  LetterSpacings,
  FontSizes,
  DestructiveButton,
  LayoutIndexes,
  PrimaryButton,
  GridColumn,
  Section,
  Sizes,
  LineHeights,
  Drawer
} from '@polestar/component-warehouse-react';
import styled from '@emotion/styled';
import { SerializedStyles, css } from '@emotion/react';
import {
  InternalColors,
  InternalFontSizes,
  InternalLetterSpacings,
  InternalLineHeights,
  InternalSecondaryButton
} from '@polestar/component-warehouse-react/internal';
import { m as motion } from 'framer-motion';
import { BookingStatuses } from '~enums';
import { ALL_OPTIONS } from '../CalendarContainer';

export const ResponsivePageTitle = styled(Heading)`
  font-size: ${FontSizes.large};

  @media (max-width: ${Breakpoints.tablet - 1}px) {
    font-size: ${InternalFontSizes.smallHeading};
    line-height: ${InternalLineHeights.large};
    letter-spacing: ${InternalLetterSpacings.large};
  }
`;

export const FullPageWrapper = styled.div<{
  removePaddingForHeader?: boolean;
  scrollXDisabledMobile?: boolean;
}>`
  overflow-x: auto;
  // If the browser does not support dvh it will be a normal page with scroll bar without any sticky content.
  @supports (height: 100dvh) {
    height: ${({ removePaddingForHeader }) =>
      removePaddingForHeader
        ? '100dvh'
        : 'calc(100dvh - 4.5rem)'}; // 4.5rem, headers height in desktop/desktopWide
    width: 100%;
    @media (max-width: ${Breakpoints.desktop - 1}px) {
      height: ${({ removePaddingForHeader }) =>
        removePaddingForHeader
          ? '100dvh'
          : 'calc(100dvh - 4rem)'}; // 4rem, headers height in tablet
    }
    @media (max-width: ${Breakpoints.tablet - 1}px) {
      height: ${({ removePaddingForHeader }) =>
        removePaddingForHeader
          ? '100dvh'
          : 'calc(100dvh - 3.5rem)'}; // 3.5rem, headers height in mobile
      overflow-x: ${props => (props.scrollXDisabledMobile ? 'hidden' : 'auto')};
      overflow-y: auto;
    }
  }
`;

export const TitleRow = styled(Row)`
  position: relative;
`;

export const HeaderCell = styled(TableCell)`
  padding-bottom: ${Spacings.xSmall};
  padding-right: ${Spacings.xLarge};
`;

export const BodyCell = styled(TableCell)<{ isLast: boolean }>`
  padding: ${Spacings.medium} ${Spacings.xLarge} ${props => (props.isLast ? 0 : Spacings.medium)} 0;
  ${({ isLast }) =>
    isLast &&
    css`
      box-shadow: none;
    `};
`;
export const BodyLastCell = styled(TableCell)`
  padding: ${Spacings.medium} ${Spacings.xLarge} 0 0;
`;

export const NintyDegImage = styled(Image)`
  transform: rotate(90deg);
  max-width: 192px;
  max-height: 108px;
  transform-origin: top left;
  margin-left: 108px;
`;

export const ModelNumberGradient = styled(Text)`
  position: absolute;
  height: 100%;
  left: -8px;
  padding-top: 90px;
  font-size: 232px;
  background: linear-gradient(#330867 0%, #30cfd0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  pointer-events: none;
`;

export const TitleRowDataColumn = styled(Column)`
  width: 100%;
`;

export const ColoredParagraph = styled(Paragraph)<{ color?: Colors }>`
  color: ${props => (props.color ? props.color : null)};
`;

export const ColoredHeading = styled(Heading)<{ color?: Colors; responsive?: boolean }>`
  color: ${props => (props.color ? props.color : null)};
  ${props =>
    props.responsive &&
    `@media (max-width: ${Breakpoints.tablet - 1}px) {
    line-height: ${LineHeights.small};
    font-size: ${FontSizes.body};
  }`}
`;

export const CheckboxContainer = styled.div<{
  checked: boolean;
  disabled?: boolean;
}>`
  padding: ${props => (props.checked ? '7px 15px' : '8px 16px')};
  border: ${props => (props.checked ? `1px solid ${Colors.accessibleSafetyOrange}` : '0')};
  display: flex;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

export const ListDivider = styled.div<{ spacing?: Spacings }>`
  border-top: 1px solid ${Colors.greyNurse};
  margin: ${({ spacing = Spacings.xSmall }) => `${spacing} 0 ${spacing} 0`};
`;

export const GrayWhiteDivider = styled.div<{ spacing?: Spacings }>`
  border-bottom: 1px solid ${Colors.greyWhite};
  margin: ${({ spacing = Spacings.medium }) => `${spacing} 0 ${spacing} 0`};
`;

export const HeadingSmall = styled(ColoredHeading)`
  font-size: ${InternalFontSizes.smallHeading};
  line-height: ${InternalLineHeights.large};
  letter-spacing: ${InternalLetterSpacings.large};
`;

export const HeadingResponsive = styled(ColoredHeading)`
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    font-size: ${InternalFontSizes.smallHeading};
    line-height: ${InternalLineHeights.large};
    letter-spacing: ${InternalLetterSpacings.large};
  }
`;

export const InternalParagraphLarge = styled(Paragraph)`
  font-size: ${InternalFontSizes.smallHeading};
  line-height: ${InternalLineHeights.large};
  letter-spacing: ${LetterSpacings.large};
`;

export const InternalParagraphSmall = styled(Paragraph)`
  font-size: ${InternalFontSizes.smallHeading};
  line-height: ${InternalLineHeights.large};
  letter-spacing: ${LetterSpacings.large};
`;

export const InternalHeading = styled(Heading)<{ color?: Colors }>`
  font-size: ${InternalFontSizes.smallHeading};
  line-height: ${InternalFontSizes.smallHeading};
  color: ${({ color }) => color};
`;

export const ResponsivePrimaryButton = styled(PrimaryButton)`
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    width: 100%;
  }
`;

export const ResponsiveInternalSecondaryButton = styled(InternalSecondaryButton)`
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    width: 100%;
  }
`;

export const FooterDialog = styled(motion.div)<{ relativePosition: boolean }>`
  z-index: ${LayoutIndexes.modal};
  position: ${({ relativePosition }) => (relativePosition ? 'relative' : 'fixed')};
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${Spacings.large};
  padding-bottom: max(1rem, calc(env(safe-area-inset-bottom) + ${Spacings.large}));
  box-shadow: 0px -8px 8px -8px rgb(123 123 123 / 30%);

  @supports (backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)) {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
  }

  @supports not ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px))) {
    background-color: rgba(255, 255, 255, 0.9);
  }

  @media (max-width: ${Breakpoints.tablet - 1}px) {
    padding: ${Spacings.medium};
    padding-bottom: max(1rem, calc(env(safe-area-inset-bottom) + ${Spacings.medium}));
  }
`;

export const CreateBatchButton = styled(PrimaryButton)`
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    width: 100%;
  }
`;

export const ResponsiveDestructiveButton = styled(DestructiveButton)`
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    width: 100%;
  }
`;

export const SelectedVinParagraph = styled(Paragraph)`
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    font-size: ${InternalFontSizes.table};
  }
`;

export const DivWithBorder = styled.div`
  border: none;
  padding: 0;
  padding-bottom: ${Spacings.medium};
  height: 100%;
  @media (min-width: ${Breakpoints.tablet}px) {
    padding: ${Spacings.medium};
    border: 1px solid ${Colors.greyNurse};
  }
  @media (min-width: ${Breakpoints.desktop}px) {
    padding: ${Spacings.large};
    border: 1px solid ${Colors.greyNurse};
  }
`;

export const GridColumnWithBorder = styled(GridColumn)<{
  removeBottomPadding?: boolean;
  hasBottomBorder?: boolean;
}>`
  border: none;
  padding: 0;
  padding-bottom: ${({ removeBottomPadding }) =>
    removeBottomPadding ? `none` : `${Spacings.medium}`};
  height: 100%;
  border-bottom: ${({ hasBottomBorder }) =>
    hasBottomBorder ? `1px solid ${Colors.greyWhite}` : `none`};

  @media (min-width: ${Breakpoints.tablet}px) {
    padding: ${Spacings.medium};
    border: 1px solid ${Colors.greyNurse};
  }
  @media (min-width: ${Breakpoints.desktop}px) {
    padding: ${Spacings.large};
    border: 1px solid ${Colors.greyNurse};
  }
`;

export const InternalSection = styled.div`
  padding: 0 ${Spacings.medium};

  @media (min-width: ${Breakpoints.desktop}px) {
    padding: 0 ${Spacings.large};
  }
`;

export const GreyTitle = styled(Heading)`
  color: ${Colors.ironGrey};
`;

export const CustomSection = styled(Section)<{
  disableRightPadding?: boolean;
}>`
  ${({ disableRightPadding }) =>
    disableRightPadding &&
    css`
      @media (min-width: ${Breakpoints.mobile}px) {
        padding-right: 0;
      }
      @media (min-width: ${Breakpoints.tablet}px) {
        padding-right: 0;
      }
      @media (min-width: ${Breakpoints.desktop}px) {
        padding-right: 0;
      }
      @media (min-width: ${Breakpoints.desktopWide}px) {
        padding-right: 0;
      }
    `}
`;

export const NotesCountDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${Colors.black};
  margin-right: 6px;
`;

export const StatusIndicator = styled.span<{
  state: BookingStatuses | typeof ALL_OPTIONS | 'Holiday' | 'Event' | '';
  size?: Sizes.large | Sizes.medium | Sizes.small;
}>`
  height: ${({ size }) => (size === Sizes.large ? '12px' : size === Sizes.small ? '4px' : '8px')};
  width: ${({ size }) => (size === Sizes.large ? '12px' : size === Sizes.small ? '4px' : '8px')};
  background-color: ${({ state }) =>
    state === BookingStatuses.CONFIRMED
      ? InternalColors.systemGreen
      : state === BookingStatuses.PENDING
      ? InternalColors.systemYellow
      : state === BookingStatuses.DECLINED
      ? Colors.error
      : state === 'Holiday'
      ? Colors.lightGold
      : state === 'Event'
      ? Colors.elementalBlue
      : 'transparent'};
  border-radius: 50%;
  display: inline-block;
`;

export const DrawerFooter = styled(motion.div)`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  padding-bottom: max(1rem, calc(env(safe-area-inset-bottom) + 0.5rem));
  align-items: center;
  background-color: ${Colors.white};
  box-shadow: rgba(123, 123, 123, 0.04) 0px -1px 1px, rgba(123, 123, 123, 0.04) 0px -2px 2px,
    rgba(123, 123, 123, 0.04) 0px -4px 4px, rgba(123, 123, 123, 0.04) 0px -8px 8px;

  @media (max-width: ${Breakpoints.tablet - 1}px) {
    padding: 1rem;
    padding-bottom: max(1rem, calc(env(safe-area-inset-bottom) + 0.5rem));
  }
`;

export const BodyCheckCell = styled.td`
  padding: ${Spacings.medium} 0 ${Spacings.medium} 0;
`;

export const StatusMarker = styled.div<{ background: SerializedStyles }>`
  min-width: 6px;
  max-width: 6px;
  min-height: 100%;
  border-radius: 16px;
  ${({ background }) => background};
`;

export const DrawerCustomWidth = styled(Drawer)`
  min-width: 500px;
  @media (max-width: ${Breakpoints.desktop - 1}px) {
    min-width: 50%;
  }
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    min-width: 100%;
  }
`;
