import React, { useCallback } from 'react';
import { Path } from '~routes/definition';
import { usePersistedState } from '~app/hooks/usePersistedState';
import OriginPageContext from './context';

const DEFAULT_ORIGIN_LOCATION = {
  pathname: Path.HOME as string,
  search: '',
  hash: ''
};

const OriginPageContextProvider = (props: any) => {
  const [originLocation, setOriginLocation] = usePersistedState('originLocationStack', [
    DEFAULT_ORIGIN_LOCATION
  ]);

  /**
   * Whenever you leave a page you want to be able to go back to through
   * a back button, this method should be used
   * @param location - leave empty to use current location
   */
  const pushOriginLocation = useCallback(
    (location?: { pathname: string; search: string; hash: string }) => {
      setOriginLocation(prev => {
        const last = prev[prev.length - 1];

        if (
          !(
            last.pathname === (location?.pathname ?? window.location.pathname) &&
            last.search === (location?.search ?? window.location.search) &&
            last.hash === (location?.hash ?? window.location.hash)
          )
        ) {
          if (prev.length > 4) {
            prev.shift();
          }
          return [
            ...prev,
            location ?? {
              pathname: window.location.pathname,
              search: window.location.search,
              hash: window.location.hash
            }
          ];
        } else return prev;
      });
    },
    [setOriginLocation]
  );

  /**
   * Should not be used with navigate
   */
  const getOriginLocation = useCallback(() => {
    return originLocation[originLocation.length - 1];
  }, [originLocation]);

  /**
   * Pops the last location from the stack, use when navigating back
   */
  const popOriginLocation = useCallback(() => {
    let location;

    setOriginLocation(prev => {
      if (prev.length > 1) {
        location = prev.pop() ?? DEFAULT_ORIGIN_LOCATION;
      }

      return [...prev];
    });
    return location ?? DEFAULT_ORIGIN_LOCATION;
  }, []);

  const providerValue = {
    getOriginLocation,
    pushOriginLocation,
    popOriginLocation
  };

  return <OriginPageContext.Provider {...props} value={providerValue} />;
};

export { OriginPageContext };
export default OriginPageContextProvider;
