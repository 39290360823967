import { useCallback } from 'react';
import { TOW_BAR_ORDERLINE_ID_PHASE_ONE } from '~helpers/handoverConverter';
import { isTowbarOrdered, towbarPreparationStep, updatePreparation } from '~helpers/handoverHelper';
import { HandoverTableData } from '~apollo/queries/getHandoverExtended';
import useHandoverState from './useHandoverState';
import useAuthState from './useAuthState';
import { isStatusPreOrdered } from '~helpers/orderHelper';
import useSendExtraStatus from './useSendExtraStatus';
import { OrderlineStatus } from '~apollo/queries/getOrder';

const useUpdateExtras = (handover: HandoverTableData, onCompleted: () => void) => {
  const { user } = useAuthState();
  const { updateHandover, handoverOrder, extras } = useHandoverState();
  const { sendExtraStatusUpdate, isUpdating } = useSendExtraStatus(onCompleted);

  const handleSaveExtras = useCallback(
    (
      extrasItemsList: Array<{
        orderlineId: number | null;
        checked: boolean;
        status: OrderlineStatus;
      }>
    ) => {
      //phase one Towbar  items are ordered differently
      const towbarItems = extrasItemsList.find(
        item =>
          item.orderlineId === TOW_BAR_ORDERLINE_ID_PHASE_ONE &&
          (item.checked ? !isTowbarOrdered(handover) : isTowbarOrdered(handover))
      );

      if (towbarItems) {
        updateHandover({
          preparations: updatePreparation(
            handover.preparations ?? [],
            user.userId,
            user.name,
            towbarPreparationStep(handover.orderNumber),
            towbarItems.checked
          )
        });
      }

      const changeToOrderedList = extrasItemsList.filter(
        item =>
          item.orderlineId !== TOW_BAR_ORDERLINE_ID_PHASE_ONE &&
          item.checked &&
          isStatusPreOrdered(item.status ?? 'pending')
      );

      const orderlineIdsToOrder: Array<number> = [];
      changeToOrderedList.forEach(item => {
        if (item.orderlineId) {
          orderlineIdsToOrder.push(item.orderlineId);
        }
      });

      if (orderlineIdsToOrder.length > 0) {
        sendExtraStatusUpdate(
          orderlineIdsToOrder,
          handoverOrder.vehicle.winterTireMount,
          'ExtrasOrderDelivery_ordered',
          extras
        );
      }

      const changeToDeliveryPlanningList = extrasItemsList.filter(
        item =>
          item.orderlineId !== TOW_BAR_ORDERLINE_ID_PHASE_ONE &&
          !item.checked &&
          !isStatusPreOrdered(item.status ?? 'pending')
      );

      const orderlineIdsToReverseOrder: Array<number> = [];
      changeToDeliveryPlanningList.forEach(item => {
        if (item.orderlineId) {
          orderlineIdsToReverseOrder.push(item.orderlineId);
        }
      });

      if (orderlineIdsToReverseOrder.length > 0) {
        sendExtraStatusUpdate(
          orderlineIdsToReverseOrder,
          handoverOrder.vehicle.winterTireMount,
          'ExtrasOrderDelivery_deliveryPlanning',
          extras
        );
      }
    },
    [
      extras,
      handover,
      handoverOrder.vehicle.winterTireMount,
      sendExtraStatusUpdate,
      updateHandover,
      user.name,
      user.userId
    ]
  );

  return { handleSaveExtras, isUpdating };
};

export default useUpdateExtras;
