import { Colors } from '@polestar/component-warehouse-react';
import { ExtraPackage, HandoverTableData } from '~apollo/queries/getHandoverExtended';
import { Extra } from '~apollo/queries/getHandoverExtras';
import { ExtraProductTypes, ExtraStatus } from '~enums';
import { OrderTableCell, checkIcon, minusIcon } from '~enums/Cells';
import {
  LEGACY_TRAILER_MODULE,
  TOW_BAR_ORDERLINE_ID_PHASE_ONE,
  UPDATED_TRAILER_MODULE
} from './handoverConverter';
import { isTowbarOrdered } from './handoverHelper';
import { isStatusPreOrdered } from './orderHelper';
import { InternalColors } from '@polestar/component-warehouse-react/internal';
import { OrderlineStatus } from '../apollo/queries/getOrder';

export const filterWheelsThatShouldNotBeOrdered = (
  handover: HandoverTableData,
  extraPackages: Array<ExtraPackage>
) => {
  let extras = extraPackages && extraPackages?.length > 0 ? [...extraPackages] : [];

  const needsWinterWheelMount = handover.orderData?.needsWinterWheelMounting;
  const wantsTogetherWithCar = extraPackages?.find(
    x => x.productType === ExtraProductTypes.WINTER_WHEELS
  )?.winterTireWantsTogetherWithCar;

  if (
    !needsWinterWheelMount &&
    !wantsTogetherWithCar &&
    wantsTogetherWithCar !== null &&
    wantsTogetherWithCar !== undefined
  ) {
    extras = extras.filter(x => x.productType !== ExtraProductTypes.WINTER_WHEELS);
  }

  return extras;
};

export const getVehiclePreparationExtras = (handover: HandoverTableData) => {
  let extras =
    handover.extraPackages && handover.extraPackages.length > 0 ? [...handover.extraPackages] : [];

  extras = filterWheelsThatShouldNotBeOrdered(handover, extras);

  return extras && extras.length > 0
    ? extras.filter(
        extra =>
          extra.orderlineStatus !== 'cancelled' && extra.productType !== 'Tax' && extra.orderlineId
      )
    : [];
};

const towbarCommonProps = {
  title: 'Towbar',
  description: 'Towbar description',
  product_type: 'Towbar',
  sortorder: 1,
  requires: [],
  incompatible: []
};

// Get parts that POMS does not add as extrsa/parts
export const getTowbarRelatedParts = (handover: HandoverTableData, articleNumber: string) => {
  const parts: Array<Extra> = [];
  switch (articleNumber) {
    case 'A00463':
      parts.push(
        ...[
          {
            ...towbarCommonProps,
            id: 1005,
            article_number: '32207226',
            title: 'Semi Electrically Retractable Towbar'
          },
          {
            ...towbarCommonProps,
            id: 1004,
            article_number:
              handover.orderData?.modelYear &&
              Number(handover.orderData.modelYear) <= 2024 &&
              handover.orderData.chassisNumber &&
              Number(handover.orderData.chassisNumber) <= 241130
                ? LEGACY_TRAILER_MODULE
                : UPDATED_TRAILER_MODULE,
            title: 'Trailer module'
          },
          {
            ...towbarCommonProps,
            id: 1003,
            article_number: '31650476',
            title: 'Bracket, trailer module'
          },
          {
            ...towbarCommonProps,
            id: 1002,
            article_number: '31399328',
            title: 'Operation button panel'
          },
          {
            ...towbarCommonProps,
            id: 1001,
            article_number: '32296317',
            title: 'SW- TRM ADD 13S'
          }
        ]
      );
      break;
    case 'A01407':
      parts.push(
        ...[
          { ...towbarCommonProps, id: 9999, article_number: '32351966', title: 'Trailer module' },
          { ...towbarCommonProps, id: 9998, article_number: '32417110', title: 'Tape for WAE' },
          { ...towbarCommonProps, id: 9997, article_number: '32414297', title: 'SW- TRM ADD 13S' }
        ]
      );
      break;
    case 'A01408':
      parts.push(
        ...[
          { ...towbarCommonProps, id: 9996, article_number: '32351966', title: 'Trailer module' },
          { ...towbarCommonProps, id: 9995, article_number: '32417110', title: 'Tape for WAE' },
          { ...towbarCommonProps, id: 9994, article_number: '32266344', title: 'Switch pack' },
          { ...towbarCommonProps, id: 9994, article_number: '32414297', title: 'SW- TRM ADD 13S' }
        ]
      );
      break;
    case 'A01409':
      parts.push(
        ...[
          { ...towbarCommonProps, id: 9993, article_number: '32351966', title: 'Trailer module' },
          { ...towbarCommonProps, id: 9992, article_number: '32417110', title: 'Tape for WAE' },
          {
            ...towbarCommonProps,
            id: 9996,
            article_number: '32132981',
            title: 'Ball holder w 2" ball'
          },
          { ...towbarCommonProps, id: 9991, article_number: '32296756', title: 'Bag jack' },
          { ...towbarCommonProps, id: 9990, article_number: '32414296', title: 'SW- TRM ADD 4/7S' }
        ]
      );
      break;
    case 'A01520':
      parts.push(
        ...[
          {
            ...towbarCommonProps,
            id: 9989,
            article_number: '80009169',
            title: 'Cable harness (TRM to towbar)'
          },
          { ...towbarCommonProps, id: 9988, article_number: '80014770', title: 'Trailer module' },
          { ...towbarCommonProps, id: 9987, article_number: '31207209', title: 'Fastener kit' },
          { ...towbarCommonProps, id: 9986, article_number: '80001861', title: 'Switch pack' },
          { ...towbarCommonProps, id: 9985, article_number: '80019136', title: 'SW- TRM ADD 13S' }
        ]
      );
      break;
    case 'A01521':
      parts.push(
        ...[
          {
            ...towbarCommonProps,
            id: 9984,
            article_number: '80009167',
            title: 'Cable harness (TRM to towbar)'
          },
          { ...towbarCommonProps, id: 9983, article_number: '80014770', title: 'Trailer module' },
          { ...towbarCommonProps, id: 9982, article_number: '31207209', title: 'Fastener kit' },
          { ...towbarCommonProps, id: 9981, article_number: '80001861', title: 'Switch pack' },
          { ...towbarCommonProps, id: 9980, article_number: '32296756', title: 'Bag jack' },
          { ...towbarCommonProps, id: 9979, article_number: '80019135', title: 'SW- TRM ADD 4/7S' }
        ]
      );
      break;
  }

  return parts;
};

export const isItemOrdered = (
  handover: HandoverTableData,
  orderlineId: number | null | undefined,
  orderlineStatus: OrderlineStatus | undefined
) => {
  return orderlineId && orderlineId === TOW_BAR_ORDERLINE_ID_PHASE_ONE
    ? isTowbarOrdered(handover)
    : orderlineStatus
    ? !isStatusPreOrdered(orderlineStatus)
    : false;
};

export const getExtraStatus = (handover: HandoverTableData, extra: ExtraPackage) => {
  const cell: OrderTableCell = {
    value: ExtraStatus.NOT_ORDERED,
    valueString: ExtraStatus.NOT_ORDERED,
    borderColor: Colors.agatheGrey,
    icon: minusIcon
  };
  if (isItemOrdered(handover, extra.orderlineId, extra.orderlineStatus)) {
    cell.value = cell.valueString = ExtraStatus.ORDERED;
    cell.borderColor = InternalColors.systemYellow;
    cell.icon = checkIcon;
  }
  if (extra.orderlineStatus === 'ready_for_delivery' || extra.orderlineStatus === 'delivered') {
    cell.value = cell.valueString = ExtraStatus.INSTALLATION_CONFIRMED;
    cell.borderColor = InternalColors.systemGreen;
    cell.icon = checkIcon;
  }

  return cell;
};
