import React, { useEffect, useCallback, useState } from 'react';
import {
  Icons,
  Row,
  Spacings,
  Text,
  PrimaryButton,
  Alignments,
  Spacer
} from '@polestar/component-warehouse-react';
import getRuntimeConfig from '~config/index';
import { MARKET_USER } from '~app/apollo/queries/getRoles';
import { generateUrl } from '~root/src/routes/helpers';
import useAuthState from '~app/hooks/useAuthState';
import useDatoState from '~app/hooks/useDatoState';
import { useLazyQuery, useQuery } from '@apollo/client';
import { SearchResponse, SearchRequest, GET_HANDOVERS } from '~apollo/queries/getHandovers';
import {
  GetOtherPortalUrlsResponse,
  GET_OTHER_PORTAL_URLS
} from '~apollo/queries/getOtherPortalUrls';
import { GetLocationsResponse, GET_LOCATIONS } from '~apollo/queries/getLocations';
import { Handover } from '~apollo/queries/getHandoverExtended';
import {
  InternalFontSizes,
  ActionProps,
  InternalHeader,
  InternalSearchDialog,
  InternalSearchResultGroupTypes,
  InternalSearchTableGroupItem,
  useInternalSearch
} from '@polestar/component-warehouse-react/internal';
import ConfirmChangeLocationDialog from '~app/components/Shared/ConfirmChangeLocationDialog';
import { useNavigate, useLocation } from 'react-router-dom';
import { OEPL_LOCATION_IDS } from '~app/apollo/queries/getLocation';
import useMarketState from '~hooks/useMarketState';
import { Path } from '~root/src/routes/definition';
import gtmHelper from '~helpers/gtmHelper';
import { TableCells } from '~enums/OrderTable';
import useTableColumns from '~hooks/useTableColumns';
import useOriginPageState from '~hooks/useOriginPageState';
import { getOriginLocationFromHandover } from '~helpers/handoverHelper';

interface Portal {
  url: string;
  name: string;
}

type Props = {
  userRole: number;
  userLocations: Array<string>;
  setIsShowingNotificationDrawer: (value: boolean) => void;
  setIsLocationDialogOpen: (value: boolean) => void;
};

const MIN_SEARCH_VALUE_LENGTH = 1;

const Header = ({
  userRole,
  userLocations,
  setIsShowingNotificationDrawer,
  setIsLocationDialogOpen
}: Props) => {
  const navigate = useNavigate();
  const config = getRuntimeConfig();
  const { text } = useDatoState();
  const { userLocation, isMarketView, userLanguage, userMarketId } = useAuthState();
  const location = useLocation();
  const { hide: hideSearchDialog } = useInternalSearch();
  const { getColumnCell } = useTableColumns(userLanguage);

  const [chosenOrder, setChosenOrder] = useState<Handover | undefined>(undefined);
  const [portalUrls, setPortalUrls] = useState<Portal[]>([]);
  const [isLocationInfoDialogOpen, setIsLocationInfoDialogOpen] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [waitForDebounce, setWaitForDebounce] = useState(false);

  const [searchResults, setSearchResults] = useState<InternalSearchTableGroupItem[]>([]);

  const { countries } = useMarketState();
  const isChina = config.build.deployRegion === 'china';

  const { pushOriginLocation } = useOriginPageState();

  const [
    getSearchResults,
    { loading: isSearching, data: { response: { totalSize = 0, hits = [] } = {} } = {} }
  ] = useLazyQuery<SearchResponse, SearchRequest>(GET_HANDOVERS, {
    onCompleted: ({ response: { hits = [] } = {} }) => {
      const results = hits.map(result => ({
        data: {
          customer: getColumnCell(TableCells.CUSTOMER_CELL, result)?.valueString ?? '',
          vin: result.vin ?? '',
          regNr: result.orderData?.vehicleRegistrationNumber ?? '',
          orderNumber: result.orderNumber
        },
        onClick: () => {
          hideSearchDialog();
          if (
            !result.orderData?.handoverPartners?.some(
              partner => partner.locationId === userLocation.id
            ) &&
            !(isMarketView && result.marketId === userMarketId)
          ) {
            setChosenOrder(result);
            setIsLocationInfoDialogOpen(true);
          } else {
            pushOriginLocation(getOriginLocationFromHandover(result));
            navigate(generateUrl(Path.HANDOVER, { id: result.orderNumber }));
          }
        }
      }));
      if (searchValue.length >= MIN_SEARCH_VALUE_LENGTH) setSearchResults(results);
    },
    fetchPolicy: 'cache-and-network'
  });

  const showLocationSwitcher = userRole >= MARKET_USER || userLocations.length > 1;

  const handleOrderUpdatesClick = useCallback(() => {
    gtmHelper.pushCallToAction({ key: 'OrderUpdates' });
    setIsShowingNotificationDrawer(true);
  }, [setIsShowingNotificationDrawer]);

  useQuery<GetOtherPortalUrlsResponse>(GET_OTHER_PORTAL_URLS, {
    fetchPolicy: 'no-cache',
    ssr: true,
    context: { dato: true },
    variables: { language: userLanguage },
    skip: !userLanguage,
    onCompleted: ({ urls = [] }) => {
      const preparedUrls = [...urls]
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map(portal => {
          let url = '';
          if (isChina) {
            if (config.application.environment === 'dev') {
              url = portal.devChina ?? portal.dev;
            } else if (config.application.environment === 'staging') {
              url = portal.stagingChina ?? portal.staging;
            } else {
              url = portal.prodChina ?? portal.prod;
            }
          } else {
            if (config.application.environment === 'dev') {
              url = portal.dev;
            } else if (config.application.environment === 'staging') {
              url = portal.staging;
            } else {
              url = portal.prod;
            }
          }
          return { url, name: portal.name };
        });
      setPortalUrls(preparedUrls);
    }
  });

  const { data: { locations = [] } = {} } = useQuery<GetLocationsResponse>(GET_LOCATIONS);

  useEffect(() => {
    if (searchValue.length >= MIN_SEARCH_VALUE_LENGTH) setWaitForDebounce(true);
    const delayDebounceFn = setTimeout(() => {
      getSearchResults({
        variables: { input: { searchValue, from: 0, size: 8 } }
      });
      setWaitForDebounce(false);
    }, 500);
    if (searchValue.length < MIN_SEARCH_VALUE_LENGTH) {
      setSearchResults([]);
      clearTimeout(delayDebounceFn);
      setWaitForDebounce(false);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [getSearchResults, searchValue]);

  const results =
    searchResults && searchResults?.length > 0
      ? {
          title: text('Orders'),
          total: totalSize,
          show: 8,
          type: InternalSearchResultGroupTypes.table as InternalSearchResultGroupTypes.table,
          headings: {
            customer: text('Customer'),
            vin: text('VIN'),
            regNr: text('RegistrationNumber'),
            orderNumber: text('OrderId')
          },
          items: searchResults
        }
      : [];

  const getInternalHeaderActions = (): Array<ActionProps> => {
    const actions: Array<ActionProps> = [];

    if (!OEPL_LOCATION_IDS.includes(userLocation.id) && !isMarketView && !isChina) {
      actions.push({
        label: text('Calendar'),
        'aria-label': text('Calendar'),
        icon: Icons.calendar,
        onClick: () => {
          navigate(generateUrl(Path.CALENDAR));
        }
      });
    }

    !isChina &&
      actions.push({
        label: text('OrderUpdates'),
        'aria-label': text('OrderUpdates'),
        icon: Icons.notification,
        onClick: handleOrderUpdatesClick,
        'data-testid': 'OrderUpdates'
      });

    return actions;
  };

  return (
    <div data-testid="cw-header">
      <InternalHeader
        title={
          <Text
            style={{ fontSize: InternalFontSizes.smallHeading, cursor: 'pointer' }}
            onClick={() => navigate(generateUrl(Path.HOME))}
          >{`${text('HandoverPortal')} ${
            config.application.environment === 'staging' ? '(Staging)' : ''
          }`}</Text>
        }
        menuButton={true}
        avatarButtonProps={false}
        location={
          showLocationSwitcher
            ? isMarketView && userMarketId === userLocation.marketId
              ? countries?.find(country => country.id === userMarketId)?.name ?? userMarketId
              : userLocation.name
            : undefined
        }
        locationButtonProps={
          showLocationSwitcher
            ? {
                icon: Icons.marker,
                onClick: () => {
                  setIsLocationDialogOpen(true);
                }
              }
            : undefined
        }
        search={{}}
        searchButtonProps={{ icon: Icons.search }}
        actions={getInternalHeaderActions()}
        dashboardButtonProps={{}}
        dashboardContextMenuProps={{
          backButtonLabel: text('Back'),
          items: portalUrls.map(portal => ({
            label: portal.name,
            onClick: () => window.location.assign(portal.url)
          }))
        }}
      />
      <InternalSearchDialog
        onQueryChange={(value: string) => {
          setSearchValue(value);
        }}
        clearOnClose
        initialSuggestions={[]}
        loading={waitForDebounce || isSearching}
        onClose={() => setSearchResults([])}
        results={results}
        footer={
          searchValue.length >= MIN_SEARCH_VALUE_LENGTH &&
          hits.length > 0 && (
            <SearchFooter hasMoreData={totalSize > hits.length} searchValue={searchValue} />
          )
        }
      />
      {isLocationInfoDialogOpen && chosenOrder && (
        <ConfirmChangeLocationDialog
          setIsLocationInfoDialogOpen={setIsLocationInfoDialogOpen}
          chosenOrderLocation={
            locations.find(loc => loc.id === chosenOrder.locationId)?.name ??
            chosenOrder.locationId ??
            ''
          }
          chosenOrderNumber={chosenOrder.orderNumber}
          handover={chosenOrder}
          resetMarketView={chosenOrder.marketId !== userMarketId}
        />
      )}
      {!(
        location.pathname.includes('calendar') ||
        location.pathname.includes('orders') ||
        location.pathname.includes('performance/comments')
      ) && <Spacer data-testid="base-header-spacing" spacing={Spacings.large} />}
    </div>
  );
};

interface SearchFooterProps {
  hasMoreData: boolean;
  searchValue: string;
}
const SearchFooter = ({ hasMoreData, searchValue }: SearchFooterProps) => {
  const { text } = useDatoState();
  const navigate = useNavigate();
  const { hide: hideSearchDialog } = useInternalSearch();

  return searchValue.length >= MIN_SEARCH_VALUE_LENGTH ? (
    <Row style={{ padding: Spacings.xLarge, paddingTop: 0 }}>
      {hasMoreData ? (
        <PrimaryButton
          icon={Icons.arrowRight}
          iconPosition={Alignments.right}
          onClick={() => {
            hideSearchDialog();
            navigate(`${generateUrl(Path.SEARCH)}?text=${searchValue}`);
          }}
          data-testid="show-all-results-button"
        >
          {text('ShowAllResults')}
        </PrimaryButton>
      ) : (
        <Text>{text('AllResultsVisible')}</Text>
      )}
    </Row>
  ) : null;
};

export default Header;
