import { createContext } from 'react';
import { Handover } from '~apollo/queries/getHandoverExtended';
import { DocHubDocument, DocumentData, DocumentType } from '~hooks/useDocuments';

type DocumentsContext = {
  documents: DocumentData[];
  fetchUploadableDocuments: (marketId: string) => Promise<void>;
  refetch: (
    orderNumber: string,
    batchId?: string | undefined
  ) => Promise<DocumentData[] | undefined>;
  fetchDocumentNameTranslations: (language: string) => Promise<void>;
  uploadableDocuments: DocumentType[];
  documentNameTranslations: {
    systemName: string;
    translatedName: string;
    englishName: string;
  }[];
  fetchAllDocumentLinks: (
    documents: DocHubDocument[],
    handover: Handover
  ) => Promise<DocHubDocument[]>;
  fetchDocumentFile: (
    docId: string,
    download: boolean,
    options?:
      | {
          docName?: string | undefined;
        }
      | undefined
  ) => Promise<void>;
  isLoading: boolean;
  isLoadingDocumentsDone: boolean;
  fetchDocumentLink: (
    docId: string,
    download: boolean,
    docName?: string | undefined
  ) => Promise<string | undefined>;
  isFleetPurchaseOrderAvailable: boolean;
  documentCurrentlyBeingFetched: string;
};

export default createContext<DocumentsContext | undefined>(undefined);
