import React from 'react';
import { OrderTableCell } from '~root/src/app/enums/Cells';
import { isNullishOrEmpty } from '~root/src/app/helpers/stringHelper';
import * as Styled from './styles';
import { Colors } from '@polestar/component-warehouse-react';

const DataChip = ({ cell, expandCell }: { cell: OrderTableCell; expandCell: boolean }) => {
  return (
    <>
      {cell.icon && cell.borderColor ? (
        isNullishOrEmpty(cell.value as string) ? (
          <Styled.IconOnly
            isExpanded={expandCell}
            borderColor={cell.borderColor}
            background={cell.backgroundColor ? cell.backgroundColor : Colors.transparent}
          >
            {cell.icon}
          </Styled.IconOnly>
        ) : (
          <Styled.ValueWithIcon
            isExpanded={expandCell}
            borderColor={cell.borderColor}
            background={cell.backgroundColor ? cell.backgroundColor : Colors.transparent}
          >
            {((expandCell && !cell.hideIconWhenExpanded) || !expandCell) && cell.icon}
            {expandCell && (
              <Styled.ValueIconText withMargin={!cell.hideIconWhenExpanded}>
                {cell.value}
              </Styled.ValueIconText>
            )}
          </Styled.ValueWithIcon>
        )
      ) : (
        <p
          style={{
            overflowWrap: 'normal',
            whiteSpace: 'nowrap',
            color: cell.fontColor ? cell.fontColor : 'inherit'
          }}
        >
          {cell.value}
        </p>
      )}
    </>
  );
};

export default DataChip;
