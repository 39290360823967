import React, { Dispatch, SetStateAction } from 'react';
import { AnimatePresence, useReducedMotion } from 'framer-motion';
import { DrawerFooter } from '~components/Shared/styles';
import useUpdateExtras from '~hooks/useUpdateExtras';
import {
  ExtraPackage,
  GET_HANDOVER_EXTENDED,
  HandoverTableData
} from '~apollo/queries/getHandoverExtended';
import {
  Alignments,
  LayoutIndexes,
  PrimaryButton,
  Transitions,
  useSnackbar
} from '@polestar/component-warehouse-react';
import useDatoState from '~hooks/useDatoState';
import { useApolloClient } from '@apollo/client';

const SaveExtrasFooter = ({
  handover,
  orderedExtras,
  activeExtras,
  showFooter,
  isSavingExtras,
  setIsSavingExtras
}: {
  handover: HandoverTableData;
  orderedExtras: Array<ExtraPackage>;
  activeExtras: Array<ExtraPackage>;
  showFooter: boolean;
  isSavingExtras: boolean;
  setIsSavingExtras: Dispatch<SetStateAction<boolean>>;
}) => {
  const { text } = useDatoState();
  const isReducedMotion = useReducedMotion();
  const { addSnackbar } = useSnackbar();
  const client = useApolloClient();

  const { handleSaveExtras, isUpdating } = useUpdateExtras(handover, () => {
    client.refetchQueries({
      include: [GET_HANDOVER_EXTENDED]
    });
    setIsSavingExtras(false);
    addSnackbar({
      content: text('SelectedExtrasUpdated{VIN}').replace(
        '{VIN}',
        handover.vin ?? handover.orderNumber
      ),
      closeOnClick: true
    });
  });

  return (
    <AnimatePresence>
      {showFooter && (
        <DrawerFooter
          animate={{ y: 0 }}
          initial={{ y: 100 }}
          exit={{ y: 100 }}
          transition={{ ...Transitions.reduced, disabled: isReducedMotion }}
          style={{ zIndex: LayoutIndexes.modal + 1 }}
        >
          <PrimaryButton
            loading={isUpdating || isSavingExtras}
            iconPosition={Alignments.right}
            disabled={isUpdating || isSavingExtras}
            onClick={() => {
              setIsSavingExtras(true);
              handleSaveExtras(
                activeExtras.map(extra => ({
                  orderlineId: extra.orderlineId ?? null,
                  status: extra.orderlineStatus ?? 'confirmed',
                  checked: orderedExtras.some(x => x.orderlineId === extra.orderlineId)
                }))
              );
            }}
          >
            {text('SaveSelected')}
          </PrimaryButton>
        </DrawerFooter>
      )}
    </AnimatePresence>
  );
};

export default SaveExtrasFooter;
